<template>
  <div class="faq-wrapper">
    <div class="faq-box">
      <div class="faq-title wow animate__fadeInLeft">FAQ</div>
      <div class="faq-content">
        <el-row :gutter="0">
          <el-col :xs="24" :sm="24" :md="14" :lg="15" :xl="16">
            <div class="faq-card wow animate__fadeInLeft">
          <el-collapse v-model="activeName" accordion v-for="(item, index) in faqList" :key="index" >
            <el-collapse-item :name="index">
              <template slot="title">
                <div class="faq-item-q">Q</div> 
                <div class="faq-item-title">{{ item.question }}</div>
              </template>
              <div class="faq-a">
                <div class="faq-a-title">A</div>
                <div class="faq-a-content">
                  <div>{{ item.answer }}</div>
                  <div class="faq-list" v-for="items in item.list" :key="items">
                    {{ items }}
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="10" :lg="9" :xl="8">
            <div class="customer-card wow animate__fadeInRight">
              <div class="customer-content">
                <div class="customer-title">Customer Service - siap membantu</div>
                <div class="customer-tip">
                  <div>Hubungi Kami di Hari Senin – Minggu</div>
                  <div>Jam 09:00 – 17:30 WIB</div>
                </div>
              </div>
              <div class="customer-btn-card">
                <div><img src="../assets/img/faq/telephone.png" alt="telephone">1500-006</div>
                <div><img src="../assets/img/faq/whatsapp.png" alt="whatsapp">0889-1500-006</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import info from '@/utils/options'
import {WOW} from 'wowjs'
export default {
  data() {
    return {
      activeName: 0,
      faqList: info.faqList
    };
  },
  mounted(){
    this.$nextTick(() => {
      var wow = new WOW({
        animateClass: 'animated',
        offset: 0,
      })
      wow.init()
    })
    sessionStorage.setItem('activeTab', '4')
  },
}
</script>

<style lang="scss" scoped>
.faq-wrapper{
  width: 100%;
  min-height: 100vh;
  background: #F2F6F1;
  padding: 90px 0 114px 0;
  box-sizing: border-box;
  .faq-box{
    max-width: 1580px;
    margin: 0 auto;
  }
  .faq-title{
    font-size: 58px;
    font-weight: bold;
    margin: 0 0 50px 60px;
  }
  .faq-content{
    display: flex;
    .faq-card{
      height: auto;
      margin-right: 30px;
      padding: 60px 130px 90px 68px;
      box-sizing: border-box;
      background: #fff;
      .faq-item-q{
        font-size: 40px;
        font-weight: 600;
        margin-right: 16px;
      }
      .faq-item-title{
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
      .faq-a{
        display: flex;
        margin-top: 10px;
      }
      .faq-a-title{
        font-size: 30px;
        font-weight: 600;
        color: #28543F;
        margin: 0 16px 0 5px;
      }
      .faq-a-content{
        font-size: 12px;
        color: #1E6642;
      }
    }
    .customer-card{
      .customer-content{
        width: 100%;
        height: 564px;
        background: url("../assets/img/faq/custom.png") no-repeat;
        background-size: cover;
        position: relative;
        margin-bottom: 40px;
        .customer-title{
          width: 424px;
          font-size: 30px;
          font-weight: 600;
          line-height: 44px;
          padding: 28px 0 0 42px;
          box-sizing: border-box;
        }
        .customer-tip{
          position: absolute;
          color: #28543F;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          left: 42px;
          bottom: 10px;
        }
      }
      .customer-btn-card{
        display: flex;
        align-items: center;
        justify-content: space-between;
        div{
          width: 225px;
          height: 60px;
          background: #B3E872;
          border-radius: 34px;
          line-height: 60px;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          color: #28543F;
          cursor: pointer;
          img{
            width: 34px;
            height: 34px;
            margin: -4px 12px 0 0;
          }
        }
      }
    }
  }
}
</style>